import Axios from "axios";
import {
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
  browserLocalPersistence,
  signOut,
} from "firebase/auth";
import { statusCheck } from "../../functions/statusCheck";

const AUTH_START = "AUTH_START";
const AUTH_SUCCESS = "AUTH_SUCCESS";
const AUTH_FAIL = "AUTH_FAIL";
const LOGOUT = "LOGOUT";

const RESET_PASSWORD_START = "RESET_PASSWORD_START";
const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

export const auth = (email, password) => {
  return async (dispatch) => {
    dispatch(authStart());

    // check if status subscrition is OK
    const resStaSubscription = await statusCheck(email);
    if (!resStaSubscription.status) {
      dispatch(authFail(resStaSubscription.message));
      return;
    }
    // ------

    try {
      const auth = getAuth();
      setPersistence(auth, browserLocalPersistence)
        .then(() => {
          // Existing and future Auth states are now persisted in the current
          // session only. Closing the window would clear any existing state even
          // if a user forgets to sign out.
          // ...
          // New sign-in will be persisted with session persistence.
          return signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
              // Signed in
              const user = userCredential.user;
              user.getIdToken().then((token) => {
                let email = user.email;
                let uid = user.uid;
                // ...
                // salvataggio delle credenziali nel loacl storge
                localStorage.setItem("token", token);
                localStorage.setItem("userId", uid);
                localStorage.setItem("email", email);
                dispatch(authSuccess(token, email, uid));
              });
            })
            .catch((error) => {
              // Handle Errors here.
              const errorCode = error.code;
              const errorMessage = error.message;
              console.log(errorCode);
              console.log(errorMessage);
              dispatch(authFail(errorMessage));
            });
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode);
          console.log(errorMessage);
          dispatch(authFail(errorMessage));
        });

      // console.log(response);

      //   console.log(response.data);
    } catch (error) {
      // console.log("Errore ==> ", error.response.data.error.message);
      dispatch(authFail(error.response.data.error.message));
    }
  };
};

export const authStart = () => {
  return {
    type: AUTH_START,
  };
};

export const authSuccess = (token, email, uid) => {
  return {
    type: AUTH_SUCCESS,
    token: token,
    userId: uid,
    email: email,
  };
};

export const checkValidSubscription = () => {
  console.log("qui");
  return async (dispatch) => {
    const email = localStorage.getItem("email");
    const resStaSubscription = await statusCheck(email);
    console.log(resStaSubscription);
    if (!resStaSubscription.status) {
      dispatch(logoutRoot());
      window.location.reload();
      return;
    }
  };
};

export const authFail = (error) => {
  return {
    type: AUTH_FAIL,
    error: error,
  };
};
export const resetPassword = (mail) => {
  return async (dispatch) => {
    dispatch(resetPasswordStart());

    try {
      const key = "AIzaSyC2tNG3_Y0CZC5z6dXSk7hygkd_W0o6QNU";
      let url = `https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=${key}`;
      const response = await Axios.post(url, {
        requestType: "PASSWORD_RESET",
        email: mail,
      });
      console.log(response);
      dispatch(resetPasswordSuccess());
    } catch (error) {
      dispatch(resetPasswordFail(error));
      console.log(error);
    }
  };
};

export const resetPasswordStart = () => {
  return {
    type: RESET_PASSWORD_START,
  };
};

export const resetPasswordSuccess = () => {
  return {
    type: RESET_PASSWORD_SUCCESS,
  };
};

export const resetPasswordFail = (error) => {
  return {
    type: RESET_PASSWORD_FAIL,
    error: error,
  };
};

export const logoutRoot = () => {
  const auth = getAuth();
  signOut(auth)
    .then(() => {
      // Sign-out successful.
    })
    .catch((error) => {
      // An error happened.
    });

  localStorage.removeItem("token");
  localStorage.removeItem("userId");
  localStorage.removeItem("email");
  return {
    type: LOGOUT,
  };
};

export const authCheck = () => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    if (!token) {
      return;
    }
    const userId = localStorage.getItem("userId");
    await dispatch(
      authSuccess({
        idToken: token,
        localId: userId,
      })
    );
    await dispatch(checkValidSubscription());
  };
};

export {
  AUTH_START,
  AUTH_SUCCESS,
  AUTH_FAIL,
  LOGOUT,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_START,
};
